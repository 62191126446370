import { useCallback, useEffect, useRef, useState } from "react";
import { ITimerangeOption } from "core/types/TimerangeOption";
import { IInsightSection } from "core/types/TemplateConfig";
import { IReportInsight } from "core/types/Report";
import { useQueryParam } from "core/hooks/useQueryParam";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import {
  DATE_RANGE_OPTIONS_FROM_VALUE,
  RELATIVE_TIMERANGE_VALUE_FROM_KEYS,
} from "core/constants/timerange";
import TimeRangePicker from "core/components/TimeRangePicker";
import InsightCard from "core/components/InsightCard/Index";
import { Flex } from "@chakra-ui/react";

export type IntervalPeriod =
  | "ONE_DAY"
  | "SEVEN_DAYS"
  | "FOURTEEN_DAYS"
  | "THIRTY_DAYS"
  | "THREE_MONTHS"
  | "SIX_MONTHS"
  | "TWELVE_MONTHS";

interface ILineContainerProps extends IReportInsight {
  LineChart: React.FC<any>;
  mockData: { [key: string]: any };
  keyAccessor: string;
  intervalType: number;
  insight: IInsightSection;
  groupId?: string | number;
  unit?: string;
  initialTimeRange?: string;
  timeRangeOptions?: ITimerangeOption[];
  showYAxis?: boolean;
  actions?: React.ReactNode;
  intervalPeriod?: IntervalPeriod;
  showFooter?: boolean;
  showActions?: boolean;
  colSpan?: number;
  showGrid?: boolean;
  showAllTimeHigh?: boolean;
  simplifyXAxis?: boolean;
  h?: number;
  showReportTitle?: boolean;
  showTooltips?: boolean;
}

export const getDefaultTimeRange = (
  initialTimeRange:
    | "ONE_DAY"
    | "SEVEN_DAYS"
    | "FOURTEEN_DAYS"
    | "THIRTY_DAYS"
    | "FOUR_WEEKS"
    | "EIGHT_WEEKS"
    | "TWELVE_WEEKS"
    | "THREE_MONTHS"
    | "SIX_MONTHS"
    | "TWELVE_MONTHS",
) => {
  if (!initialTimeRange) return;

  const dateValue = RELATIVE_TIMERANGE_VALUE_FROM_KEYS[initialTimeRange];

  type DateRangeOptionsType =
    | 86400
    | 604800
    | 1209600
    | 2592000
    | 7776000
    | 15552000
    | 31536000;

  const isDateRangeOptionsType = (
    value: number,
  ): value is DateRangeOptionsType => {
    return (
      value === 86400 ||
      value === 604800 ||
      value === 1209600 ||
      value === 2592000 ||
      value === 7776000 ||
      value === 15552000 ||
      value === 31536000
    );
  };

  if (isDateRangeOptionsType(dateValue)) {
    return DATE_RANGE_OPTIONS_FROM_VALUE[dateValue];
  }
};

export const getTimerangeDays = (
  timerange: ITimerangeOption,
  intervalPeriod: IntervalPeriod,
) => {
  if (!timerange) return;
  const seconds = Number(RELATIVE_TIMERANGE_VALUE_FROM_KEYS[timerange.value]);
  const periodSeconds = RELATIVE_TIMERANGE_VALUE_FROM_KEYS[intervalPeriod];

  return Math.floor(seconds / periodSeconds);
};

export const LineContainer: React.FC<ILineContainerProps> = ({
  LineChart,
  report,
  insight,
  previewMode,
  sharingMode,
  screenshotMode,
  config,
  sharingSecretToken,
  initialTimeRange,
  timeRangeOptions,
  intervalType,
  keyAccessor,
  intervalPeriod,
  mockData,
  actions = null,
  unit = "",
  showYAxis,
  additionalConfigProps,
  showInProgress = true,
  showTooltips = true,
  showFooter = true,
  showActions = true,
  colSpan = 2,
  showGrid = true,
  showAllTimeHigh = true,
  simplifyXAxis = false,
  h = 300,
  showReportTitle,
}) => {
  const [timerange, setTimerange] = useState<ITimerangeOption>(
    getDefaultTimeRange(
      additionalConfigProps?.initialTimeRange || initialTimeRange,
    ) ||
      additionalConfigProps?.initialTimeRange ||
      initialTimeRange,
  );
  const groupId = useQueryParam("groupId");

  const [showATH, setShowATH] = useState(showAllTimeHigh && !groupId);

  const timerangeDays =
    intervalPeriod && getTimerangeDays(timerange, intervalPeriod);

  const setAndSaveTimerange = (timerange: ITimerangeOption) => {
    setTimerange(timerange);
    localStorage.setItem(
      `${insight.slug}_LastTimeRange`,
      JSON.stringify(timerange),
    );
  };

  const isSetupValid = config.validateSetup(report.config);
  const isFirstRender = useRef(true);

  const {
    response,
    isLoading,
    isFetching,
    error,
    refetch: originalRefetch,
    onRefreshInsight,
  } = useInsightDataFetching({
    report,
    insight,
    insightParams: {
      interval: timerangeDays,
      ...(keyAccessor === "powerUsers" && {
        daysOfUsage: timerange.value,
      }),
    },
    sharingSecretToken,
    previewMode,
    skip: !isSetupValid,
  });

  const refetchCallback = useCallback(() => {
    if (!isFirstRender.current) {
      originalRefetch();
    }
    isFirstRender.current = false;
  }, [originalRefetch, isFirstRender]);

  useEffect(() => {
    refetchCallback();
  }, [timerange, refetchCallback]);

  const data = previewMode ? mockData : response?.data;

  const allTimeHigh = !groupId && response?.data?.allTimeHigh;

  console.log(`allTimeHigh`, allTimeHigh);

  return (
    <InsightCard>
      {(Card) => (
        <Card.Container
          data-testid={`${insight.slug}-container`}
          id={insight.slug}
          insight={insight}
          colSpan={colSpan}
        >
          <Card.Header
            hasCaching={response?.hasCaching}
            screenshotMode={screenshotMode}
            showReportTitle={showReportTitle || sharingMode}
            report={report}
            config={config}
            insight={insight}
            showActions={!sharingMode && !previewMode && showActions}
            sharingMode={sharingMode}
            refresh={onRefreshInsight}
            cachedAt={response?.cachedAt}
            additionalConfigProps={{
              initialTimeRange: timerange?.value,
            }}
          >
            {Boolean(allTimeHigh) && (
              <div className="mr-4 flex items-center gap-x-2">
                <input
                  id="show-ath"
                  name="show-ath"
                  type="checkbox"
                  aria-describedby="show-ath-description"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  checked={showATH}
                  onChange={() => setShowATH(!showATH)}
                />

                <p className="text-xs font-medium text-gray-600">
                  All time high
                </p>
              </div>
            )}
            {actions}
            {timerange && timeRangeOptions && (
              <TimeRangePicker
                previewMode={previewMode}
                timerange={timerange}
                setTimeRange={setAndSaveTimerange}
                options={timeRangeOptions}
              />
            )}
          </Card.Header>
          <Card.Body
            h={h}
            isLoading={isLoading || isFetching}
            isPreviewMode={previewMode}
            isSetupValid={isSetupValid}
            error={error}
            insight={insight}
          >
            <Flex
              bg="white"
              borderRadius="lg"
              direction="column"
              justify="center"
              h="full"
            >
              {data && data[keyAccessor] && (
                <LineChart
                  key={showATH}
                  intervalType={intervalType}
                  unit={unit}
                  report={report}
                  graphId={insight.title}
                  data={data[keyAccessor]}
                  settings={report?.config?.settings}
                  previewMode={previewMode}
                  sharingMode={sharingMode}
                  insight={insight}
                  timerange={timerange}
                  allTimeHigh={allTimeHigh}
                  showYAxis={showYAxis}
                  showGrid={showGrid}
                  showInProgress={showInProgress}
                  showAllTimeHigh={showATH}
                  simplifyXAxis={simplifyXAxis}
                  showTooltips={showTooltips}
                />
              )}
            </Flex>
          </Card.Body>
          {insight.description && !screenshotMode && showFooter && (
            <Card.Footer>
              <Card.DescriptionAccordion insight={insight} />
            </Card.Footer>
          )}
        </Card.Container>
      )}
    </InsightCard>
  );
};
