import { useParams } from "react-router-dom";
import { SyntheticEvent, useState } from "react";
import { IViewInsightContainer } from "modules/ViewInsight/ViewInsight";
import { ViewInsightCard } from "modules/ViewInsight/CardContainer";
import { IInsightSection, ITemplateConfig } from "core/types/TemplateConfig";
import {
  useAddViewInsightMutation,
  useDeleteViewInsightMutation,
} from "core/models/viewInsights";
import { AppObjectType } from "core/models/appObjects";
import { useViews } from "core/hooks/useViews";
import { useToast } from "core/hooks/useToast";
import { usePaywall } from "core/hooks/usePaywall";
import { useOnboardingChecklist } from "core/hooks/useOnboardingChecklist";
import useInsightDataFetching from "core/hooks/useInsightDataFetching";
import useFlag from "core/hooks/useFlag";
import {
  COLOR_NAME_TO_PALETTE,
  GRID_WIDTH_TO_SIZE,
} from "core/hooks/useEditViewInsight";
import { FUNNEL, REPORT_CONFIGS } from "core/constants/report-configurations";
import { NEW_ONBOARDING } from "core/constants/features";
import { Flex } from "@chakra-ui/react";
import { GettingStartedOverlay } from "./GettingStartedOverlay";

export function getReportConfigByType(reportType: number) {
  return REPORT_CONFIGS.find((config) => config.reportType === reportType);
}

function findInsightByTypeId(
  reportConfig: ITemplateConfig,
  insightTypeId: number,
) {
  return reportConfig.insights.find(
    (insight: IInsightSection) => insight.typeId === insightTypeId,
  );
}

export function getInsightConfig(reportType: number, insightTypeId: number) {
  const reportConfig = getReportConfigByType(reportType);
  return reportConfig ? findInsightByTypeId(reportConfig, insightTypeId) : null;
}

export const ViewInsightContainer: React.FC<IViewInsightContainer> = ({
  location,
  viewInsight,
  isEditing,
  isAdding,
  isAdded,
  viewId,
  isBlurred,
  onSetViewInsight,
}) => {
  const toast = useToast();
  const { reachedLimit } = useViews({ location });
  const { shouldBePaywalled } = usePaywall();
  const { appId, viewId: paramViewId, groupId } = useParams();
  const _viewId = viewId ? viewId : paramViewId;
  const [showHighlightDescription, setShowHighlightDescription] =
    useState(false);
  const [deleteViewInsight] = useDeleteViewInsightMutation();
  const [addViewInsight] = useAddViewInsightMutation();
  const { response, isLoading, isFetching } = useInsightDataFetching({
    report: viewInsight.report,
    insightType: viewInsight.insightId,
    insightParams: {
      ...viewInsight.insight?.defaultParams,
      size: GRID_WIDTH_TO_SIZE[viewInsight.gridWidth],
      breakdown_enabled:
        viewInsight.insight.reportType === FUNNEL &&
        Boolean(viewInsight.report.config.breakdown)
          ? false
          : undefined,
    },
    sharingSecretToken: null,
    previewMode: false,
    groupType: groupId ? undefined : viewInsight?.appObject?.slug,
    timerangeType: viewInsight?.timerangeType,
    timerangeValue: viewInsight?.timerangeValue,
    timerangeStartTimestamp: viewInsight?.timerangeStartTimestamp,
    timerangeEndTimestamp: viewInsight?.timerangeEndTimestamp,
  });
  const { isSetupComplete } = useOnboardingChecklist();
  const hasNewOnboardingEnabled = useFlag(NEW_ONBOARDING);

  if (viewInsight.insightId === undefined) return null;

  const insightConfig = getInsightConfig(
    viewInsight.insight.reportType,
    viewInsight.insightId,
  );
  const data = insightConfig?.view
    ? insightConfig?.view?.transform(response?.data, viewInsight.interval)
    : [];
  const highlight = insightConfig?.view?.highlight
    ? insightConfig?.view?.highlight(response?.data)
    : {};
  const timerange = insightConfig?.view?.timerange
    ? insightConfig?.view?.timerange(viewInsight)
    : "";
  const Component = insightConfig?.view ? (
    insightConfig?.view?.Components &&
    viewInsight.graphType &&
    viewInsight.graphType !== "null" &&
    insightConfig?.view?.Components[
      viewInsight.graphType as keyof typeof insightConfig.view.Components
    ] ? (
      insightConfig?.view?.Components[
        viewInsight.graphType as keyof typeof insightConfig.view.Components
      ]
    ) : (
      insightConfig?.view?.Component
    )
  ) : (
    <></>
  );

  const color = COLOR_NAME_TO_PALETTE[viewInsight?.color as string];

  const isPaywalled =
    (shouldBePaywalled &&
      viewInsight?.appObject?.objectType &&
      [AppObjectType.Company, AppObjectType.Group].includes(
        viewInsight?.appObject?.objectType,
      )) ||
    false;
  const filter =
    isPaywalled || isBlurred
      ? "blur(10px)"
      : !isSetupComplete
        ? "grayscale(0.8)"
        : "none";

  return (
    <ViewInsightCard
      viewInsight={viewInsight}
      isEditing={isEditing}
      isAdding={isAdding}
      isAdded={isAdded}
      isBlurred={isBlurred}
      isPaywalled={isPaywalled}
      isEmpty={data?.length === 0}
      groupId={groupId}
      highlight={highlight}
      title={viewInsight.title || response?.reportName}
      showHighlightDescription={showHighlightDescription}
      description={viewInsight.insight.name}
      isLoading={isLoading || isFetching}
      timerange={
        !isEditing && !isAdding && !isBlurred && timerange ? timerange : ""
      }
      hasTimerangePicker={insightConfig?.view?.hasTimerangePicker}
      dateRangeOptions={insightConfig?.view?.dateRangeOptions}
      reachedLimit={reachedLimit}
      onMouseEnter={() => setShowHighlightDescription(true)}
      onMouseLeave={() => setShowHighlightDescription(false)}
      onDelete={(e: SyntheticEvent) => {
        e.preventDefault();
        deleteViewInsight({
          id: viewInsight.id,
          appId: viewInsight.appId,
        }).then(() => {
          toast({
            title: "Insight removed",
            description: "The insight has been removed from the view",
            status: "success",
          });
        });
      }}
      onAdd={(e: SyntheticEvent) => {
        e.preventDefault();
        addViewInsight({
          appId: Number(appId),
          viewId: Number(_viewId),
          reportId: Number(viewInsight.reportId),
          insightId: Number(viewInsight.insightId),
          objectType: viewInsight?.appObject?.objectType,
        }).then(() => {
          toast({
            title: "Insight added",
            description: "The insight has been added to the view",
            status: "success",
          });
        });
      }}
      position="relative"
      color={color}
      onSetViewInsight={onSetViewInsight}
    >
      {hasNewOnboardingEnabled
        ? !isSetupComplete &&
          showHighlightDescription && <GettingStartedOverlay />
        : null}
      <Flex
        py={2}
        h="full"
        w="full"
        filter={filter}
        position="relative"
        opacity={!isSetupComplete ? 0.4 : undefined}
      >
        <Component
          data={data}
          {...data}
          color={color}
          size={GRID_WIDTH_TO_SIZE[viewInsight.gridWidth]}
          interval={viewInsight.interval}
          measure={viewInsight.measure}
        />
      </Flex>
    </ViewInsightCard>
  );
};
